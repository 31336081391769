import React, { useEffect, useState } from 'react'
import './Answer.css'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'
import Button from '../Button/Button'
import { useParams, useNavigate } from 'react-router-dom'
import '../Screens/RateScreen/RateScreen.css'
import RatePlugin from '../Screens/RateScreen/RatePlugin'
// import LoadGif from '../../Media/load.gif'

const Answer = ({ question, answer, user }) => {

  // useEffect(() => {
  //   console.log("CURRENT USER", user);
  // }, [user])

  const [rateAnswerPopUp, setRateAnswerPopUp] = useState(false);

  const [article, setArticle] = useState(undefined)
  const navigate = useNavigate();

  const {id} = useParams();

  const updateRating = (rating) => {
    fetch(`/backend/api/qas/x?filter[UniqID]=${user.UniqID}`, {method: 'PUT', body: JSON.stringify({data: {'AnswerRating': rating}}), headers: {'Content-Type': 'application/json'}})
  }

  useEffect(() => {
    if (id && !question && !answer) {
      fetch(`/backend/api/articles/${id}?populate[0]=Content`).then(response => response.json().then(data => {
        setArticle(data.data);
        // console.log("Article: ", data.data)x
      }))
    }
  }, [])

  return (
    <div className='answer__container'>
      <div>
        {!article && answer ? <p className='answer__question-text'>{question}</p> : !article && <p style={{marginTop: 20}}>Ваш вопрос ещё находится в обработке,  попробуй зайти позже.</p>}
        {article || answer ? <div className='answer__text'><ReactMarkdown children={article? article.attributes.Content[0].Text : answer} remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]} ></ReactMarkdown></div> : <div style={{height: 338, marginTop: 32, width: '100%', display: 'flex', justifyContent: 'center'}}><img src={process.env.PUBLIC_URL + '/time_1.gif'} alt='Waiting...'/></div>}
      </div>
      {article || answer ? <Button backgroundColor={'#27B99E'} buttonText={'Прочитал (-а)'} linkTo={'/'}/> : <Button backgroundColor={'#27B99E'} buttonText={'Хорошо'} linkTo={'/'}/>}
    </div>
)
}

export default Answer
