import React from 'react'
import './Header.css'
import { Link } from 'react-router-dom';
import logo from '../../Media/Logo.svg'
import EmergingContacts from '../Modals/EmergingContacts/EmergingContacts'
import Menu from './Menu';

const Header = ({ callModal, modalElement, isOpened }) => {

  const OpenCloseMenu = () => {
    if (modalElement === undefined) {
      callModal(<Menu callModal={callModal}/>)
    } else {
      callModal(undefined)
    }
  }


  return (
    <>
      <div className='header__container'>
      <Link to={'/'} style={{width: '57%'}}>
        <img className='header__logo' src={logo} alt='logo'></img>
        </Link>
        <div className={`header__name`}>
          Детский&nbsp;сад&nbsp;№&nbsp;18 д.&nbsp;Казанцево
        </div>
      </div>
    </>
  )
}
export default Header
