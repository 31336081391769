import React, { useEffect } from 'react';
import './WelcomeScreen.css';
import { Routes, Route } from "react-router-dom";
import Slider from './Slider/Slider';
import background1 from '../../../Media/WelcomeBackground1.svg';

const WelcomeScreen = ({ onClose }) => {

  useEffect(() => {
    function handleResize() {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    handleResize();

    window.addEventListener('resize', handleResize);
  },[])

  const content = [
    {
      title: 'На этом сайте можно задать вопрос напрямую заведующему. Если хотите задать вопрос, переходите по стрелке',
      text: '',
      backgroundImage: background1,
    },
  ]

  return (
    <>
      <>
      <Routes>
        <Route path="/" element={
          <section className='welcome'>
            <Slider content={content} onClose={() => onClose()} />
          </section>
        } />
      </Routes>
    </>
    </>
  )
}
export default WelcomeScreen
