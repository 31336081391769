import React from 'react'

const ClientInput = ({onChange, placeholder, iconClass, inputHeight}) => {
  return (
    <div style={{border: '1px solid #D8D1EA', borderRadius: '8px', height: '56px', }}>
        <label className={`${iconClass} label_icon`} style={{position: 'relative'}}>
            <input style={{margin: '16px'}}  type={'text'} placeholder={placeholder} onChange={onChange}/>
        </label>
    </div>
  )
}

export default ClientInput