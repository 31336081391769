import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import './PersonalCode.css'
import Button from '../Button/Button';
import classNames from 'classnames';


const PersonalCode = ({ personalCode }) => {
  const [isCopiedMsg, setIsCopiedMsg] = useState(undefined)
  const navigate = useNavigate();

  function copyToClipboard(textToCopy) {
    if (navigator.clipboard && window.isSecureContext) {
      return navigator.clipboard.writeText(personalCode);
    } else {
      let textArea = document.createElement("textarea");
      textArea.value = textToCopy;
      textArea.style.position = "fixed";
      textArea.style.left = "-999999px";
      textArea.style.top = "-999999px";
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      return new Promise((res, rej) => {
        document.execCommand('copy') ? res() : rej();
        textArea.remove();
      });
    }
  }

  var personalCodeClasses = classNames(
    'PersonalCode__code',
    {
      'PersonalCode__code_copied': isCopiedMsg
    }
  );

  return (
    <>
      <div className='PersonalCode__container'>
        <div>
          <p className=''>Чтобы посмотреть ответ на свой вопрос:</p>
          <ul style={{marginBottom: 20}}>
            <li className='PersonalCode__item'><span style={{fontWeight: "700"}}>сохраните</span> уникальный код.</li>
            <li className='PersonalCode__item'><span style={{fontWeight: "700"}}>вставьте</span> его в блокнот или заметки.</li>
            <div style={{marginTop: 32, marginBottom: 20}} className={personalCodeClasses}>{personalCode}</div>
            {isCopiedMsg &&
              <div className='PersonalCode__msg'>{isCopiedMsg}</div>}

            {isCopiedMsg && <p>Введите его на главной странице в поле «<span style={{fontWeight: "700"}}>ввести код</span>» и получи ответ.</p>}
          </ul>
        </div>

        {isCopiedMsg && <p>Мы постараемся максимально оперативно ответить на Ваш вопрос.<br/>Вы точно получите ответ не позже двух дней.</p>}

        {!isCopiedMsg ?
          <Button backgroundColor={'#27B99E'}
            buttonText={'Скопировать'}
            onClickAction={() => { copyToClipboard(personalCode); setIsCopiedMsg('Код скопирован'); }} />
          :
          <Button backgroundColor={'#27B99E'}
            buttonText={'На главную'}
            onClickAction={() => { navigate('/', { replace: true }) }} />
        }
        {/* <p style={{ paddingTop: '10px' }}>Ответ будет готов через 48 часов.</p> */}
      </div>

    </>
  )
}
export default PersonalCode
