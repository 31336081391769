import React, { useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import './Login.css'
import EnterNameBlock from './EnterNameBlock/EnterNameBlock'

const Login = ({ setCurrentUser, getAnswer, errorMsg, setErrorMsg }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [cookies, setCookie, removeCookie] = useCookies(['school'])

  useEffect(() => {
    const school = searchParams.get('from');
    if (school){
      fetch(`/backend/api/schools/${school}`).then(response => response.json().then(data => {
        console.log("SCHOOL DATA", data);
        const uni_school = data.data;
        if (uni_school && !data.error){
          // console.log("setting cookie school", uni_school.id);
          setCookie('school', uni_school.id);
        }
      }))
    }
  }, [])

  return (
    <>
      <section className='login'>
        <div>
          <EnterNameBlock buttonText={'Задать свой вопрос'}
            text={'— Если желаете, представьтесь.'}
            placeholder={'Введите имя'}
            type={'name'}
            linkTo={'/ask'}
            setCurrentUser={setCurrentUser}
            backgroundColor={'#428FFB'}
            />
        </div>

        <div>
          <EnterNameBlock buttonText={'Получить ответ'}
            text={'— Если Вы ждете ответ, введите код в поле ниже.'}
            placeholder={'Введите уникальный код'}
            getAnswer={getAnswer}
            backgroundColor={'#27B99E'}
            errorMsg={errorMsg}
            setErrorMsg={setErrorMsg}
            />
        </div>
      </section>
    </>
  )
}
export default Login
